import React, { FunctionComponent, ReactChild } from 'react';
import Markdown from '../../../components/markdown/Markdown';
import LayoutWrapper from '../../../layouts/layout-wrapper/LayoutWrapper';
import { BackgroundImage } from '../../../types';
import * as styles from './Hero.style';

export interface HeroProps {
  dataRef?: string,
  title: string,
  description?: string,
  backgroundImage?: BackgroundImage,
  primarySlot?: ReactChild,
  secondarySlot?: ReactChild,
  secondarySlotTitle?: string,
}

const Hero: FunctionComponent<HeroProps> = ({
  dataRef = 'hero',
  title,
  description,
  backgroundImage,
  primarySlot,
  secondarySlot,
  secondarySlotTitle,
}) => (
  <header
    data-ref={dataRef}
    css={styles.base}
    $backgroundImage={backgroundImage}
  >
    <LayoutWrapper>
      <div css={styles.content}>
        <div
          css={styles.primary}
          $secondarySlot={secondarySlot}
          $backgroundImage={backgroundImage}
        >
          <h1
            data-ref={`${dataRef}-title`}
            css={styles.title}
            $backgroundImage={backgroundImage}
          >
            <Markdown
              disallowedElements={['p']}
            >
              {title}
            </Markdown>
          </h1>
          {description && (
            <div
              data-ref={`${dataRef}-description`}
              css={styles.description}
              $secondarySlot={secondarySlot}
              $backgroundImage={backgroundImage}
            >
              <Markdown
                disallowedElements={['p']}
              >
                {description}
              </Markdown>
            </div>
          )}
          {primarySlot && (
            <div css={styles.primaryContent}>
              {primarySlot}
            </div>
          )}
        </div>
        {secondarySlot && (
          <div css={styles.secondary}>
            <div css={styles.box}>
              {secondarySlotTitle && (
                <h2 css={styles.boxTitle}>
                  {secondarySlotTitle}
                </h2>
              )}
              {secondarySlot}
            </div>
          </div>
        )}
      </div>
    </LayoutWrapper>
  </header>
);

export default Hero;
