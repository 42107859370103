import { css } from 'styled-components';
import {
  SpacingName, getTypography, TypographyName, PRIMARY_FONT_FAMILY, BreakpointQueryName, ThemedFunctor, getSpacing,
} from '@doveit/lego';

export enum TileContentAlign {
  TOP = 'flex-start',
  BOTTOM = 'flex-end',
}

export enum TileRatio {
  W1_H1,
  W11_H16,
  W3_H1_RESPONSIVE,
}

const XSRatio: { [key in TileRatio]: string } = {
  [TileRatio.W1_H1]: '100%',
  [TileRatio.W11_H16]: '145.5%',
  [TileRatio.W3_H1_RESPONSIVE]: '100%',
};

const MDRatio: { [key in TileRatio]: string } = {
  [TileRatio.W1_H1]: '100%',
  [TileRatio.W11_H16]: '145.5%',
  [TileRatio.W3_H1_RESPONSIVE]: '33%',
};

export const base: ThemedFunctor = ({
  theme,
  $ratio,
  onClick,
}) => css`
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  max-height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;

  ${onClick && `
    cursor: pointer;
  `}

  &:before {
    content: "";
    display: block;
    height: 0;
    padding-bottom: ${XSRatio[$ratio as TileRatio]};

    ${theme.breakpoints.up(BreakpointQueryName.MD)} {
      padding-bottom: ${MDRatio[$ratio as TileRatio]};
    }
  }
`;

export const title: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  font-weight: bold;

  &, a {
    text-decoration: none;
    color: ${theme.palettes.neutrals.lightest};
  }
`;

export const imageWrapper = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const wrapper: ThemedFunctor = ({
  theme,
  $contentAlign,
}) => css`
  align-items: ${$contentAlign};
  color: ${theme.palettes.neutrals.darkest};
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const content: ThemedFunctor = ({
  theme,
  $image,
  $contentAlign,
}) => css`
  ${getSpacing.padding(theme, SpacingName.SMALLER)}
  color: ${theme.palettes.neutrals.darkest};
  font-family: ${PRIMARY_FONT_FAMILY};
  position: relative;
  width: 100%;

  ${$image && `
    text-shadow: 0 2px 6px #000;

    &:before {
      bottom: 0;
      content: "";
      display: block;
      background: linear-gradient(${$contentAlign === TileContentAlign.TOP ? '0' : '180deg'}, rgba(51, 51, 51, 0) 0%, ${theme.palettes.neutrals.darkest} 100%);
      border-radius: 0 0 6px 6px;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
    }
  `}
`;

export const innerContent: ThemedFunctor = () => css`
  position: relative;
  z-index: 1;
`;
