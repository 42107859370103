import { css } from 'styled-components';
import {
  getTypography,
  getSpacing,
  TypographyName,
  BreakpointQueryName,
  SpacingName,
  ThemedFunctor,
  FontWeight,
  grid,
  gridUnit,
} from '@doveit/lego';

export const base: ThemedFunctor = ({
  theme,
  $backgroundImage,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.BIGGER, 0])}
  position: relative;
  background-color: ${$backgroundImage ? theme.palettes.neutrals.darkest : theme.palettes.neutrals.lightest};

  ${$backgroundImage && `
    background-size: cover;
    background-position: center center;

    &::before {
      display: none;
      content: '';
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      background-image: linear-gradient(to top, rgba(0,0,0,0.2) , rgba(0,0,0,0.55));
      z-index: 1;
    }
  `}

  ${$backgroundImage?.SMALL && `
    background-image: url('${$backgroundImage.SMALL}');

    &::before {
      display: block;
    }
  `}

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    ${$backgroundImage && `
      background-image: url('${$backgroundImage.LARGE}');

      &::before {
        display: block;
      }
    `}
  }
`;

export const content: ThemedFunctor = ({
  theme,
}) => css`
  ${grid(theme, {
    gutter: SpacingName.SMALL,
  })}
  position: relative;
  z-index: 2;
`;

export const primary: ThemedFunctor = ({
  theme,
  $secondarySlot,
  $backgroundImage,
}) => css`
  ${gridUnit(theme, $secondarySlot
    ? {
      gutter: SpacingName.SMALL,
      size: {
        LG: 7 / 12,
      },
    }
    : {
      gutter: SpacingName.SMALL,
      size: 1,
    })}
  display: flex;
  flex-direction: column;

  ${$backgroundImage && `
    color: ${theme.palettes.neutrals.lightest};
  `}

  ${theme.breakpoints.up(BreakpointQueryName.SM)} {
    text-align: center;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    text-align: left;
  }

  ${!$secondarySlot && `
    align-items: center;
    text-align: center !important;
  `}
`;

export const secondary: ThemedFunctor = ({ theme }) => css`
  ${gridUnit(theme, { size: { LG: 5 / 12 } })}
  ${getSpacing.padding(theme, SpacingName.SMALL)}
`;

export const title: ThemedFunctor = ({ theme, $backgroundImage }) => css`
  ${getTypography(theme, TypographyName.H1)}
  font-weight: ${FontWeight.NORMAL};

  ${$backgroundImage && `
    text-shadow: 0 2px 8px ${theme.palettes.neutrals.darkest};
  `}

  strong {
    font-weight: ${FontWeight.BOLD};
  }
`;

export const description: ThemedFunctor = ({
  theme,
  $secondarySlot,
  $backgroundImage,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
  ${getSpacing.paddingTop(theme, SpacingName.MEDIUM)}
  ${getTypography(theme, TypographyName.H3)}
  font-weight: normal;
  position: relative;

  ${$backgroundImage && `
    text-shadow: 0 2px 8px ${theme.palettes.neutrals.darkest};
  `}

  &::before {
    content: '';
    width: 6rem;
    height: 4px;
    border-radius: 2px;
    background-color: ${$backgroundImage ? theme.palettes.neutrals.lightest : theme.palettes.brand.primary};
    position: absolute;
    top: -2px;
    left: 0;

    ${theme.breakpoints.up(BreakpointQueryName.SM)} {
      left: 50%;
      transform: translateX(-50%);
    }

    ${$secondarySlot && `
      ${theme.breakpoints.up(BreakpointQueryName.LG)} {
        left: 0;
        transform: translateX(0);
      }
    `}

    ${!$secondarySlot && `
      left: 50%;
      transform: translateX(-50%);
    `}
  }

  ${theme.breakpoints.up(BreakpointQueryName.SM)} {
    text-align: center;
  }

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    text-align: left;
  }

  ${!$secondarySlot && `
    text-align: center !important;
  `}
`;

export const primaryContent: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
`;

export const boxTitle: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.H4)}
  ${getSpacing.paddingBottom(theme, SpacingName.SMALL)}
`;

export const box: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.padding(theme, SpacingName.SMALL)};
  background: ${theme.palettes.neutrals.lightest};
  border: 1px solid ${theme.palettes.neutrals.light};
  border-radius: 6px;
  max-width: 32rem;
  margin: 0 auto;
  box-shadow: -1.25rem 1.25rem 1.25rem rgba(0,0,0,0.05);
`;
