import React, { FunctionComponent } from 'react';
import {
  Button,
  ButtonColor,
  ButtonVariant,
} from '@doveit/lego';
import PageSection from '../../../components/page-section/PageSection';
import { TextAlign } from '../../../types';
import ServicesHList from '../../../components/services-hlist/ServicesHList';
import { Service } from '../../../../domain/service';
import { URLObject } from '../../../utils/links';

export interface OurServicesSectionProps {
  title: string,
  description?: string,
  services: Service[],
  callToAction?: {
    label?: string,
    urlObject?: URLObject,
    onClick?: VoidFunction,
  },
}

/** @deprecated use JSX in page wherever you want and with the style you want, instead of this */
export const OurServicesSection: FunctionComponent<OurServicesSectionProps> = ({
  title,
  description,
  services = [],
  callToAction,
}) => (
  <PageSection>
    <PageSection.Wrapper>
      <PageSection.Title textAlign={TextAlign.CENTER}>
        {title}
      </PageSection.Title>
      {description && (
        <PageSection.Description
          text={description}
          textAlign={TextAlign.CENTER}
        />
      )}
    </PageSection.Wrapper>
    <PageSection.Wrapper withoutPad>
      <PageSection.Block>
        <ServicesHList
          services={services}
        />
      </PageSection.Block>
    </PageSection.Wrapper>
    {callToAction && (
      <PageSection.Wrapper>
        <PageSection.Actions textAlign={TextAlign.CENTER}>
          <Button
            label={callToAction.label}
            variant={ButtonVariant.OUTLINE}
            color={ButtonColor.PRIMARY}
            href={callToAction.urlObject?.as}
            onClick={callToAction.onClick}
            data-ref="our-services-section-cta"
          />
        </PageSection.Actions>
      </PageSection.Wrapper>
    )}
  </PageSection>
);

export default OurServicesSection;
