import React, { FunctionComponent } from 'react';
import { Button } from '@doveit/lego';
import { contentStyle, textStyle } from './BottomBar.style';

export interface BottomBarProps {
  expanded?: boolean,
  hiddenFrom?: string,
  label: string,
  title: string,
  headline?: string,
  onClick: VoidFunction,
}

const BottomBar: FunctionComponent<BottomBarProps> = ({
  label,
  title,
  headline,
  onClick,
  expanded = true,
  hiddenFrom,
}) => (
  <div
    css={contentStyle}
    $expanded={expanded}
    $hiddenFrom={hiddenFrom}
  >
    {headline && (
      <div
        css={textStyle}
        data-ref="seller-sticky-headline"
      >
        {headline}
      </div>
    )}
    <Button
      dataRef="sticky-link-lead-form"
      label={label}
      expanded
      title={title}
      onClick={onClick}
    />
  </div>
);

export default BottomBar;
