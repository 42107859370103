import React, { FunctionComponent, useRef } from 'react';
import {
  SpacingName, SymbolDirection, Symbol, HListProps, HList, useScrollPercentage, ICON_CHEVRON,
} from '@doveit/lego';
import * as styles from './CardsHList.style';

export type CardsHListComposition = {
  Item?: typeof HList.Item,
};

export type CardsHListProps = Pick<HListProps, 'sideSpacing' | 'inline' | 'itemWidth' | 'children'>;

const DEFAULT_INLINE = 4;
const DEFAULT_ITEM_WIDTH = '18rem';

const CardsHList: FunctionComponent<CardsHListProps> & CardsHListComposition = ({
  sideSpacing = SpacingName.ZERO,
  inline = DEFAULT_INLINE,
  itemWidth = DEFAULT_ITEM_WIDTH,
  children,
}) => {
  const childrenCount = React.Children.count(children);
  const scrollRef = useRef<HTMLDivElement>();
  const {
    scrollPercentage, next, prev, onScroll,
  } = useScrollPercentage(scrollRef, childrenCount);

  return (
    <div css={styles.base}>
      {childrenCount > inline && (
        <button
          data-ref="prev-button"
          css={styles.prev}
          $visible={scrollPercentage > 0}
          onClick={prev}
        >
          <Symbol
            path={ICON_CHEVRON}
            direction={SymbolDirection.LEFT}
            size={30}
          />
        </button>
      )}
      <HList
        sideSpacing={sideSpacing}
        inline={inline}
        itemWidth={itemWidth}
        scrollRef={scrollRef}
        onScroll={onScroll}
      >
        {children}
      </HList>
      {childrenCount > inline && (
        <button
          data-ref="next-button"
          css={styles.next}
          $visible={scrollPercentage < 1}
          onClick={next}
        >
          <Symbol
            path={ICON_CHEVRON}
            direction={SymbolDirection.RIGHT}
            size={30}
          />
        </button>
      )}
    </div>
  );
};

CardsHList.Item = HList.Item;

export default CardsHList;
