/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Image, ImageProps,
} from '@doveit/lego';
import Link from 'next/link';
import React from 'react';
import { URLObject } from '../../utils/links';
import * as styles from './Tile.style';

export { TileContentAlign, TileRatio } from './Tile.style';

export type TileProps = React.PropsWithChildren<{
  title: string,
  urlObject?: URLObject,
  image: Pick<ImageProps, 'src' | 'alt'>,
  anchorTitle?: string,
  contentAlign?: styles.TileContentAlign,
  ratio?: styles.TileRatio,
  onClick?: VoidFunction,
  loading?: React.ImgHTMLAttributes<HTMLImageElement>['loading'],
  lazyLoad?: boolean,
}>;

export const Tile: React.FC<TileProps> = ({
  title,
  urlObject,
  image,
  contentAlign = styles.TileContentAlign.BOTTOM,
  ratio = styles.TileRatio.W11_H16,
  anchorTitle,
  loading,
  lazyLoad,
  onClick,
  children,
}) => (
  <div
    css={styles.base}
    $ratio={ratio}
    title={anchorTitle || title}
    onClick={urlObject?.url ? onClick : undefined}
  >
    <div css={styles.imageWrapper}>
      <Image
        src={image.src}
        alt={image.alt}
        width="100%"
        height="100%"
        loading={loading}
        lazyLoad={lazyLoad}
        fit
      />
    </div>
    <div
      css={styles.wrapper}
      $contentAlign={contentAlign}
    >
      <div
        css={styles.content}
        $image={image}
        $contentAlign={contentAlign}
      >
        <div css={styles.innerContent}>
          <h3 css={styles.title}>
            {urlObject?.url ? (
              <Link
                href={urlObject.url}
                as={urlObject.as}
              >
                {title}
              </Link>
            ) : title}
          </h3>
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default Tile;
