import React, { FunctionComponent } from 'react';
import * as styles from './SectionDivider.style';

export enum SectionDividerSize {
  S = '25rem',
  M = '50rem',
  L = '75rem',
}

export interface SectionDividerProps {
  maxWidth?: SectionDividerSize,
}

const SectionDivider: FunctionComponent<SectionDividerProps> = ({
  maxWidth,
  ...others
}) => (
  <div
    css={styles.base}
    $maxWidth={maxWidth || SectionDividerSize.L}
    {...others}
  />
);

export default SectionDivider;
