/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Image, Button, ButtonSize, ImageProps, ButtonProps,
} from '@doveit/lego';
import * as styles from './InfoBlock.style';
import { CTA } from '../../types';

export interface InfoBlockProps {
  image: ImageProps,
  title: string,
  description?: string,
  cta?: CTA & Pick<ButtonProps, 'onClick' | 'disabled'>,
  onClick?: VoidFunction,
  verticalOnly?: boolean,
  highlighted?: boolean,
}

const InfoBlock: React.FC<InfoBlockProps> = ({
  image,
  title,
  description,
  cta,
  onClick,
  verticalOnly,
  highlighted,
}) => (
  <article
    css={styles.base}
    $verticalOnly={verticalOnly}
  >
    {onClick && (
      <div
        css={styles.overClick}
        onClick={onClick}
        role="button"
      />
    )}
    <div
      css={styles.image}
      $verticalOnly={verticalOnly}
    >
      <Image
        src={image.src}
        alt={image.alt}
        loading="lazy"
      />
    </div>
    <div
      css={styles.title}
      $highlighted={highlighted}
    >
      {title}
    </div>
    {description && (
      <div
        css={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
    {cta && (
      <div css={styles.cta}>
        <Button
          data-ref="cta"
          size={ButtonSize.SMALL}
          {...cta}
        />
      </div>
    )}
  </article>
);

export default InfoBlock;
