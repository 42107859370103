import React from 'react';
import { Grid, SpacingName } from '@doveit/lego';
import InfoBlock from '../../../components/info-block/InfoBlock';
import PageSection from '../../../components/page-section/PageSection';
import { TextAlign } from '../../../types';
import { requireFromStaticAsset } from '../../../utils/assetsUtils';

export interface Reason {
  title?: string,
  picture?: string,
  description?: string,
}

export interface InfoSectionProps {
  title: string,
  description?: string,
  reasons: Reason[],
}

const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  description,
  reasons = [],
}) => (
  <PageSection>
    <PageSection.Wrapper>
      <PageSection.Title textAlign={TextAlign.CENTER}>
        {title}
      </PageSection.Title>
      {description && (
        <PageSection.Description text={description} />
      )}
      <PageSection.Block>
        <Grid gutter={SpacingName.SMALLER}>
          {reasons.map((reason) => (
            <Grid.Unit
              size={{ XS: 1, MD: 1 / reasons.length }}
              key={reason.title}
            >
              <InfoBlock
                image={{
                  src: requireFromStaticAsset(reason.picture),
                  alt: `Icona ${title}`,
                }}
                title={reason.title}
                description={reason.description}
              />
            </Grid.Unit>
          ))}
        </Grid>
      </PageSection.Block>
    </PageSection.Wrapper>
  </PageSection>
);

export default InfoSection;
