import { css } from 'styled-components';
import {
  grid, gridUnit, rgba, FontWeight, ThemedFunctor,
} from '@doveit/lego';

export const contentStyle: ThemedFunctor = ({
  theme,
  $expanded,
  $hiddenFrom,
}) => css`
  ${grid(theme)}
  padding: .75rem ${theme.spaceUnits.mobile.SMALL} 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${theme.zIndex.inpage};
  background-color: ${rgba(theme.palettes.neutrals.lightest, 0.9)};
  box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
  will-change: transform;
  transform: translateY(${$expanded ? '0' : '100%'});
  transition: transform ease-out .4s;

  ${$hiddenFrom && `
    ${theme.breakpoints.up($hiddenFrom)} {
        display: none;
      }
  `}
`;

export const textStyle: ThemedFunctor = ({
  theme,
}) => css`
  ${gridUnit(theme, { size: 1 })}
  text-align: center;
  font-weight: ${FontWeight.NORMAL};
  padding: ${theme.spaceUnits.mobile.SMALLEST};
`;
