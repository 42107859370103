import { css } from 'styled-components';
import {
  BreakpointQueryName,
  ThemedFunctor,
} from '@doveit/lego';

export const base = () => css`
  position: relative;
`;

export const prev: ThemedFunctor = ({
  theme,
  $visible,
}) => css`
  display: none;

  ${theme.breakpoints.up(BreakpointQueryName.LG)} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    align-items: center;
    z-index: ${theme.zIndex.overlay};
    opacity: ${$visible ? 1 : 0};
    transition: opacity 0.2s ease-out;
    pointer-events: ${$visible ? 'all' : 'none'};
    background-color: ${theme.palettes.neutrals.lightest};
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.25);
    cursor: pointer;
    transform: translateY(-50%);
    margin-left: .25rem;
    outline: 0;
    border: 0;

    &:hover {
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.5);
    }
  }
`;

export const next: ThemedFunctor = (props) => css`
  ${prev(props)}

  ${props.theme.breakpoints.up(BreakpointQueryName.LG)} {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: .25rem;
  }
`;
