import { css } from 'styled-components';
import {
  grid, gridUnit, getSpacing, SpacingName, ThemedFunctor, JustifyContent,
} from '@doveit/lego';

export const list: ThemedFunctor = ({
  theme,
}) => css`
  ${grid(theme, {
    halign: JustifyContent.CENTER,
  })};
  ${getSpacing.margin(theme, [SpacingName.NEGATIVE_SMALLER, SpacingName.NEGATIVE_SMALLEST])}
`;

export const item: ThemedFunctor = ({
  theme,
  $inlineItems,
}) => css`
  ${gridUnit(theme, { size: { XS: 1 / 2, MD: 1 / $inlineItems } })}
  ${getSpacing.padding(theme, [SpacingName.SMALLER, SpacingName.SMALLEST])}

  a, img {
    display: block;
  }
`;
