import { css } from 'styled-components';
import {
  getSpacing, getTypography, BreakpointQueryName, TypographyName, SpacingName, ThemedFunctor,
} from '@doveit/lego';

const smallImageSize = '4rem';
const largeImageSize = '6.25rem';

export const base: ThemedFunctor = ({ theme, $verticalOnly }) => css`
  text-align: center;
  position: relative;

  ${!$verticalOnly && `
    padding-left: ${getSpacing.sum(smallImageSize, '1rem')};
    text-align: left;

    ${theme.breakpoints.up(BreakpointQueryName.MD)} {
      padding-left: 0;
      text-align: center;
    }
  `}
`;

export const overClick = css`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
`;

export const title: ThemedFunctor = ({ theme, $highlighted }) => css`
  ${getTypography(theme, TypographyName.H4)}
  ${$highlighted && `
    color: ${theme.palettes.brand.primary};
  `}
`;

export const description: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
`;

export const image: ThemedFunctor = ({ theme, $verticalOnly }) => css`
  display: inline-block;

  ${!$verticalOnly ? `
    position: absolute;
    left: 0;
    width: ${smallImageSize};
    height: ${smallImageSize};
    top: -0.05rem;

    ${theme.breakpoints.up(BreakpointQueryName.LG)} {
      position: relative;
      left: 0;
      width: ${largeImageSize};
      height: ${largeImageSize};
    }
  ` : `
    width: ${largeImageSize};
    height: ${largeImageSize};
  `}
`;

export const cta: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  position: relative;
  z-index: 2;
`;
