import React from 'react';
import { Image } from '@doveit/lego';
import * as styles from './LogoList.style';
import { URLObject } from '../../utils/links';

export interface LogoListProps {
  items: {
    id: string | number,
    alt: string,
    picture: string,
    urlObject?: URLObject,
  }[],
  inlineItems?: number,
}

const LogoList: React.FC<LogoListProps> = ({
  items,
  inlineItems = 4,
}) => (
  <ul css={styles.list}>
    {items.map((item) => {
      let Tag: keyof JSX.IntrinsicElements = 'div';
      let extraAttributes = {};

      if (item.urlObject?.as) {
        Tag = 'a';
        extraAttributes = {
          rel: 'nofollow noopener noreferrer',
          target: '_blank',
          href: item.urlObject.as,
        };
      }

      return (
        <li
          key={item.id}
          $inlineItems={inlineItems}
          css={styles.item}
          data-ref="logo-grid-item"
        >
          <Tag
            {...extraAttributes}
            data-ref="logo-item"
          >
            <Image
              src={item.picture}
              alt={item.alt}
              loading="lazy"
            />
          </Tag>
        </li>
      );
    })}
  </ul>
);

export default LogoList;
