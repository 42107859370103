import { css } from 'styled-components';
import { getSpacing, SpacingName, ThemedFunctor } from '@doveit/lego';

export const base: ThemedFunctor = ({
  theme,
  $maxWidth,
}) => css`
  ${getSpacing.padding(theme, [SpacingName.ZERO, SpacingName.SMALL])}
  margin: 0 auto;
  max-width: ${$maxWidth};

  &::before {
    content: '';
    height: 1px;
    background-color: ${theme.palettes.neutrals.light};
    display: block;
  }
`;
